import { b2x } from '@b2x/react/src';

import { CardContentSection, CardsRowsContentSection } from './contentTypes';
import { CustomCta } from './CustomCta';
import { Section } from './Section';

export interface CardsRowsSectionProps extends CardsRowsContentSection {}

const CardBox = ({ card }: { card: CardContentSection }) => {
  const { getPagePath } = b2x.useAppStaticContext();

  return (
    <b2x.ConditionalWrapper
      condition={card.cta !== undefined}
      wrapper={
        <b2x.router.Link
          to={card.cta?.to?.href ? card.cta.to.href : card.cta?.to?.code && getPagePath(card.cta.to.code)}
        />
      }
    >
      <b2x.Div className="position-relative">
        <b2x.Div className="position-absolute w-100 h-100">
          <b2x.Div
            alignItems="start"
            className="py-lg-5 py-4 my-lg-1 my-2 px-4 px-4 mx-lg-3 mx-2 h-100"
            display="flex"
            flexDirection="column"
            justifyContent="spaceBetween"
          >
            <b2x.Row>
              <b2x.Col size={{ lg: 8, xs: 12 }}>
                <b2x.H3 className={`text-${card.textColor}`}>{card.title}</b2x.H3>
              </b2x.Col>
            </b2x.Row>
            {card.cta && <CustomCta {...card.cta} />}
          </b2x.Div>
        </b2x.Div>
        <b2x.AssetV1 className="row-0 rounded-2" {...card.asset} fluid />
      </b2x.Div>
    </b2x.ConditionalWrapper>
  );
};

export const CardsRowsSection = ({ firstRow, secondRow, title }: CardsRowsSectionProps) => {
  return (
    <Section className="my-5 py-lg-5 py-3" headerBlockNoMargin title={title}>
      <b2x.Row className="mt-lg-5 mt-2" gap={3}>
        {firstRow?.map((card) => (
          <b2x.Col key={card.contentSectionId} size={{ sm: 6, xs: 12 }}>
            <CardBox card={card} />
          </b2x.Col>
        ))}
      </b2x.Row>
      <b2x.Row className="d-sm-none d-block mt-3" gap={3}>
        {secondRow?.map((card) => (
          <b2x.Col key={card.contentSectionId} size={{ sm: 6, xs: 12 }}>
            <CardBox card={card} />
          </b2x.Col>
        ))}
      </b2x.Row>
      <b2x.Div className="d-none d-sm-block mt-3">
        <b2x.SwiperContext>
          <b2x.Swiper
            breakpoints={{
              sm: {
                slidesPerView: 2.3,
              },
              xl: {
                slidesPerView: 3,
              },
              xs: {
                slidesPerView: 1,
              },
            }}
            slides={secondRow?.map((card) => (
              <CardBox card={card} key={card.contentSectionId} />
            ))}
            spaceBetween={16}
            watchSlidesProgress
          />
        </b2x.SwiperContext>
      </b2x.Div>
    </Section>
  );
};
