import './PageHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from './Container';

export interface PageHeaderProps {
  asset?: b2x.contentTypes.AssetContentSectionV1;
  className?: string;
  shortDescription?: string;
  title?: string;
}

export const PageHeader = ({ asset, className, shortDescription, title }: PageHeaderProps) => {
  return (
    <section className="page-header mb-lg-3">
      <Container>
        <div className={classnames('page-header-container', className)}>
          <b2x.Row gap={0}>
            <b2x.Col className="d-flex align-items-center order-2" size={{ lg: '', xs: 12 }}>
              <div className="w-100 pt-3 p-lg-5">
                <div className="breadcrumb-container">
                  <b2x.Breadcrumb classNameObject={{ ol: 'm-0' }} />
                </div>
                {title && (
                  <div className="title-container">
                    <h1 className="display-2">{b2x.formatHtml(title)}</h1>
                  </div>
                )}
                {shortDescription && (
                  <div className="short-description-container">
                    <b2x.ShowMore
                      activeOnBreakpoint={{ md: true, sm: true, xs: true }}
                      content={shortDescription}
                      truncateTo={200}
                    />
                    {/* <p className="m-0">{b2x.formatHtml(shortDescription)}</p> */}
                  </div>
                )}
              </div>
            </b2x.Col>
            {asset && (
              <b2x.Col
                className="order-1 order-lg-3 d-flex align-items-center justify-content-center justify-content-lg-end"
                size={{ lg: 6, xs: 12 }}
              >
                <div>
                  <b2x.AssetV1 {...asset} fluid />
                </div>
              </b2x.Col>
            )}
          </b2x.Row>
        </div>
      </Container>
    </section>
  );
};
