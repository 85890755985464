import './SliderA.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Button } from '../Button';
import { SliderAContentSection } from '../contentTypes';
import { Icon } from '../Icon';

export interface SliderAProps extends SliderAContentSection {}

export const SliderA = ({ slides, ...otherProps }: SliderAProps) => {
  const { getPagePath } = b2x.useAppStaticContext();

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <b2x.EqualHeight>
      <b2x.SwiperContext>
        {({ navigationNextElRef, navigationPrevElRef, paginationElRef }) => (
          <b2x.SwiperFromContent
            {...otherProps}
            className="slider-a"
            navigation={{ custom: true }}
            pagination={{ clickable: true, custom: true }}
            parallax
            slides={slides?.map(({ asset, contentSectionId, textBlock }) => (
              <div key={contentSectionId} style={{ position: 'relative' }}>
                <div className="position-relative h-100">
                  <b2x.ConditionalWrapper
                    condition={textBlock?.cta !== undefined}
                    wrapper={
                      <b2x.router.Link
                        to={
                          textBlock?.cta?.to?.href
                            ? textBlock.cta.to.href
                            : textBlock?.cta?.to?.code && getPagePath(textBlock.cta.to.code)
                        }
                      />
                    }
                  >
                    <b2x.EqualHeightElement name="asset">
                      <b2x.AssetV1 {...asset} fluid />
                      {textBlock?.cta && <b2x.DeprecatedCta className="stretched-link" cta={textBlock.cta} />}
                    </b2x.EqualHeightElement>
                  </b2x.ConditionalWrapper>
                </div>
                <b2x.Div className="text-block-coverer">
                  <b2x.Container className="text-block-container">
                    <b2x.Div className="text-block-resetter">
                      <b2x.Div
                        className={classnames(
                          'text-block',
                          `text-${textBlock?.textColor}`,
                          { start: textBlock?.alignment === 'start' },
                          { end: textBlock?.alignment === 'end' }
                        )}
                        marginBottom={{ lg: 0, xs: 2 }}
                        marginEnd={{ lg: textBlock?.alignment === 'end' ? 5 : 0 }}
                        marginStart={{ lg: textBlock?.alignment === 'start' ? 5 : 0 }}
                        paddingEnd={{ lg: textBlock?.alignment === 'end' ? 5 : 0 }}
                        paddingStart={{ lg: textBlock?.alignment === 'start' ? 5 : 0 }}
                      >
                        <b2x.H3
                          className="title display-1 fw-bold"
                          data-swiper-parallax="-400"
                          data-swiper-parallax-duration="500"
                          marginBottom={{ lg: 3, xs: 2 }}
                        >
                          {b2x.formatHtml(textBlock?.title)}
                        </b2x.H3>
                        <b2x.P
                          className="body h4 fw-normal ff-karla"
                          data-swiper-parallax="-400"
                          data-swiper-parallax-duration="550"
                          marginBottom={{ lg: 4, xs: 2 }}
                        >
                          {b2x.formatHtml(textBlock?.content)}
                        </b2x.P>
                        <b2x.CtaFromContent
                          {...textBlock?.cta}
                          ctaProps={{ button: { className: 'cta' } }}
                          data-swiper-parallax="-400"
                          data-swiper-parallax-duration="600"
                          variant={b2x.untilBreakpoint('md', currentBreakpoint) ? 'primary' : textBlock?.cta?.variant}
                        />
                      </b2x.Div>
                      <b2x.Div
                        className={classnames(
                          'disclaimer mb-xl-5 pb-lg-2',
                          `text-${textBlock?.disclaimerTextColor}`,
                          { start: textBlock?.alignment === 'start' },
                          { end: textBlock?.alignment === 'end' },
                          { 'ms-lg-5 ps-lg-5': textBlock?.alignment === 'start' },
                          { 'me-lg-5 pe-lg-5': textBlock?.alignment === 'end' }
                        )}
                      >
                        <b2x.Div
                          className="extra-small"
                          data-swiper-parallax="-400"
                          data-swiper-parallax-duration="650"
                        >
                          {b2x.formatHtml(textBlock?.disclaimer)}
                        </b2x.Div>
                      </b2x.Div>
                    </b2x.Div>
                  </b2x.Container>
                </b2x.Div>
              </div>
            ))}
          >
            <b2x.Div
              className="position-absolute top-0 start-0 bottom-0 end-0"
              style={{ pointerEvents: 'none', zIndex: 2 }}
            >
              <b2x.Div display={{ lg: 'none', xs: 'block' }}>
                <b2x.EqualHeightElement name="asset">
                  <span></span>
                </b2x.EqualHeightElement>
                <b2x.Div
                  alignItems="center"
                  className="pagination-wrapper"
                  display="flex"
                  innerRef={paginationElRef}
                  justifyContent="center"
                  style={{ pointerEvents: 'all' }}
                />
              </b2x.Div>
              <b2x.Container className="h-100 position-relative d-none d-lg-block">
                <b2x.Div className="position-relative h-100">
                  <b2x.Div
                    className={classnames('position-absolute top-50 start-0 text-start')}
                    style={{ pointerEvents: 'all', transform: 'translateY(-50%) translateX(0)' }}
                  >
                    <Button innerRef={navigationPrevElRef} variant="blank">
                      <Icon className="text-white" name="arrow-left" size={72} />
                    </Button>
                  </b2x.Div>
                  <b2x.Div
                    className={classnames('position-absolute top-50 end-0 text-start')}
                    style={{ pointerEvents: 'all', transform: 'translateY(-50%) translateX(0)' }}
                  >
                    <Button innerRef={navigationNextElRef} variant="blank">
                      <Icon className="text-white" name="arrow-right" size={72} />
                    </Button>
                  </b2x.Div>
                </b2x.Div>
              </b2x.Container>
            </b2x.Div>
          </b2x.SwiperFromContent>
        )}
      </b2x.SwiperContext>
    </b2x.EqualHeight>
  );
};
