import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback } from 'react';

import { useAppContext } from '../AppContext';
import { Button, ButtonVariant } from '../Button';
import { Container } from '../Container';
import { MiscellaneousContentType, ProductPromoCodeContentType } from '../contentTypes';
import { CustomVariantRadio } from '../CustomVariantRadio';
import { ResourceKeys, t } from '../i18n/i18n';
import { Icon, IconName, IconSize } from '../Icon';
import animazioneCampanella from '../images/product/animazione-campanella.json';
import { ProducTiletStickers } from '../ProductTileStickers';
import { ProductsSlider } from '../slider/ProductsSlider';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });

  const { isFooterCopyrightVisible, isTopBarRowVisible } = useAppContext();

  const [variantsDivContainer, scrollToVariantsDivContainer] = b2x.useScrollTo(-180);
  const { session } = b2x.useAppContext();

  const currentBreakpoint = b2x.useBreakpoint();

  const renderSkuOptions = useCallback((options?: b2x.OptionProps<b2x.SkuApiDto>) => {
    const colorName = options?.data?.attributes?.find((attribute) => attribute.typeCode === 'ARX_COLORE')?.value;
    const colorHex = options?.data?.attributes?.find((attribute) => attribute.typeCode === 'ARX_COLORE_HEX')?.value;
    const profumazioneName = options?.data?.attributes?.find(
      (attribute) => attribute.typeCode === 'ARX_PROFUMAZIONE'
    )?.value;
    const profumazioneImage = options?.data?.image?.src;

    if (!colorName && !profumazioneName) {
      return <div style={{ height: 25 }}>{options?.label}</div>;
    }

    return (
      <div className="d-flex gap-2 align-items-center">
        {colorHex ? (
          <div
            className="border rounded-circle"
            style={{
              backgroundColor: colorHex,
              height: 25,
              width: 25,
            }}
          />
        ) : (
          <b2x.ImageFromContentV1
            className="rounded-circle"
            src={{
              xs: {
                height: 25,
                url: profumazioneImage,
                width: 25,
              },
            }}
            style={{ height: 25, width: 25 }}
          />
        )}
        <span>{profumazioneName ?? colorName}</span>
      </div>
    );
  }, []);

  return (
    <Page className="product-page" greyBackground noPaddingTop thingsToLoadBeforePageReady={[product]}>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <React.Fragment>
              <b2x.structuredData.Product product={product} selectedSku={productContext.selectedSku} />
              <section className="product-first-section py-5">
                <b2x.Container>
                  {b2x.untilBreakpoint('md', currentBreakpoint) && (
                    <b2x.Col size={12}>
                      <div className="mb-2 px-3 p-lg-0">
                        <b2x.Breadcrumb hideLastItem />
                      </div>
                    </b2x.Col>
                  )}
                  <b2x.Row>
                    <b2x.Col size={{ lg: 6, xs: 12 }}>
                      <div className="position-relative">
                        <div className="btn-wishlist-container position-absolute end-0 p-3" style={{ zIndex: 100 }}>
                          <b2x.WishlistButtonHelper product={product} sku={productContext.selectedSku}>
                            {({ handleWishlistButtonClick, inWishlist }) => (
                              <Button
                                className="text-light-blue"
                                iconEnd={{
                                  name: inWishlist ? 'wishlist-full' : 'wishlist',
                                  size: 30,
                                }}
                                onClick={handleWishlistButtonClick}
                                variant="blank"
                              />
                            )}
                          </b2x.WishlistButtonHelper>
                        </div>
                        <ProductGallery
                          selectedProductVariant={productContext.selectedProductVariant}
                          selectedSku={productContext.selectedSku}
                        />
                      </div>
                    </b2x.Col>
                    <b2x.Col size={{ lg: 6, xs: 12 }}>
                      <div className="px-3 p-lg-0">
                        {!b2x.untilBreakpoint('md', currentBreakpoint) && (
                          <b2x.Div className="mb-3">
                            <b2x.Breadcrumb hideLastItem />
                          </b2x.Div>
                        )}
                        {product.brand?.name && <h5 className="ff-karla fw-normal">{product.brand.name}</h5>}
                        {productContext.selectedProductVariant.name && (
                          <h1 className="product-title h2 fw-bold">
                            {b2x.formatHtml(productContext.selectedProductVariant.name)}
                          </h1>
                        )}
                        {productContext.selectedSku?.measurement && !product.assembled && (
                          <p className="small mb-2 mb-md-3">
                            {b2x.formatHtml(
                              t('productTile.measurement', {
                                measurementUnit: productContext.selectedSku.measurement.measurementUnit?.toLowerCase(),
                                value: productContext.selectedSku.measurement.value,
                              })
                            )}
                          </p>
                        )}
                        {product.assembled && (
                          <>
                            <p>{t('product.kitSection.title')}</p>
                            {product.assembledComponents && product.assembledComponents.length > 0 && (
                              <ul className="list-unstyled">
                                {product.assembledComponents.map((assembledComponent, index) => {
                                  const sku = assembledComponent.componentSkus?.at(0)?.sku;
                                  return (
                                    <li className="h5 mb-3" key={`assembledComponent-${assembledComponent.id}`}>
                                      {sku?.product?.name}
                                      {sku?.measurement &&
                                        `, ${sku.measurement.measurementUnit?.toLocaleLowerCase()} ${
                                          sku.measurement.value
                                        }`}
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </>
                        )}

                        {productContext.selectedProductVariant.description && (
                          <div className="mb-2 mb-md-3 mt-4">
                            <b2x.ShowMore
                              content={`${productContext.selectedProductVariant.description}<div class="mt-2">${productContext.selectedProductVariant.code}</div>`}
                              truncateTo={400}
                            />
                          </div>
                        )}
                        <b2x.AddToCartFormHelper className="mb-3 mb-md-4" product={product} scope="product">
                          {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                            <React.Fragment>
                              {fieldsHelper.productVariants.formFields.length > 1 && (
                                <div ref={variantsDivContainer}>
                                  <b2x.FormGroup
                                    {...fieldsHelper.productVariants.formGroup}
                                    className="mt-4"
                                    label={undefined}
                                    labelClassName="small"
                                    noMarginBottom
                                  >
                                    {fieldsHelper.productVariants.formFields.map((formField) => (
                                      <b2x.Radio key={formField.productVariant.id} {...formField.radio} inline>
                                        {formField.productVariant.skus?.map(
                                          (sku, indexSku) =>
                                            indexSku === 0 && <CustomVariantRadio key={sku.id} sku={sku} />
                                        )}
                                      </b2x.Radio>
                                    ))}
                                  </b2x.FormGroup>
                                </div>
                              )}
                              {fieldsHelper.skus.formFields.length > 1 && (
                                <b2x.FormGroup
                                  {...fieldsHelper.skus.formGroup}
                                  className="mt-3"
                                  label={undefined}
                                  noMarginBottom
                                >
                                  <b2x.DynamicSelect<b2x.SkuApiDto>
                                    {...fieldsHelper.skus.select}
                                    options={fieldsHelper.skus.formFields.map(({ option, sku }) => ({
                                      ...option,
                                      data: sku,
                                    }))}
                                    renderOption={renderSkuOptions}
                                  />
                                </b2x.FormGroup>
                              )}
                              <ProductPrice priceHelper={priceHelper} />
                              <div className="hstack gap-3 mb-3 mb-md-4">
                                {selectedSku?.state === 'AVAILABLE' && (
                                  <b2x.FormGroup
                                    className="quantity-container"
                                    {...fieldsHelper.quantity.formGroup}
                                    label={undefined}
                                    noMarginBottom
                                  >
                                    <div className="d-flex">
                                      <div className="btn-container" style={{ flex: '0 0 auto' }}>
                                        <b2x.Button
                                          className="px-0"
                                          variant="gray-200"
                                          {...fieldsHelper.buttons.quantity.decrease}
                                          iconStart={{ name: 'minus', size: 12 }}
                                          label={undefined}
                                        />
                                      </div>
                                      <div className="px-2" style={{ flex: '0 0 auto', width: 45 }}>
                                        <b2x.NumberInput
                                          className="border-0 text-center fw-bold bg-transparent px-0 py-2"
                                          {...fieldsHelper.quantity.numberInput}
                                          placeholder={undefined}
                                          readOnly
                                        />
                                      </div>
                                      <div className="btn-container" style={{ flex: '0 0 auto' }}>
                                        <b2x.Button
                                          className="px-0"
                                          variant="gray-200"
                                          {...fieldsHelper.buttons.quantity.increase}
                                          iconStart={{ name: 'plus', size: 12 }}
                                          label={undefined}
                                        />
                                      </div>
                                    </div>
                                  </b2x.FormGroup>
                                )}
                                <div className="add-to-cart-button-container">
                                  <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                    fieldsHelper={fieldsHelper}
                                    selectedSku={selectedSku}
                                    submitButton={{
                                      iconEnd: { name: 'cart', size: 20 },
                                      label:
                                        selectedSku?.state === 'AVAILABLE'
                                          ? t('form.addToCartForm.buttons.submit.label')
                                          : t('misc.productNotAvailable'),
                                      variant: selectedSku?.state === 'AVAILABLE' ? 'primary' : 'outline-primary',
                                    }}
                                  />
                                </div>
                              </div>
                              <b2x.Portal>
                                <b2x.ProductStickyFooter
                                  fieldsHelper={fieldsHelper}
                                  formik={formik}
                                  isVisible={!isTopBarRowVisible && !isFooterCopyrightVisible}
                                  priceHelper={priceHelper}
                                  product={product}
                                  scrollToElement={scrollToVariantsDivContainer}
                                  selectedProductImage={selectedProductVariant.image}
                                  selectedSku={selectedSku}
                                />
                              </b2x.Portal>
                            </React.Fragment>
                          )}
                        </b2x.AddToCartFormHelper>
                        <ProductPromoCode attributes={product.attributes} />
                        <ProductFiles product={product} selectedSku={productContext.selectedSku} />
                        {session?.cart?.shippingProfile?.freeThreshold && (
                          <div className="small text-blue mt-3">
                            <Icon className="me-1" name="shipping" size={20} />
                            {b2x.formatHtml(
                              t('product.freeShippingFrom', {
                                freeShippingFromAmount: b2x.formatCurrency(session.cart.shippingProfile.freeThreshold),
                              })
                            )}
                          </div>
                        )}
                      </div>
                    </b2x.Col>
                  </b2x.Row>
                </b2x.Container>
              </section>
              <KitContentSection product={product} />
              <VideoSection product={product} />
              <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                {({ fetching, relatedProducts }) =>
                  fetching ? (
                    <b2x.Loading />
                  ) : (
                    relatedProducts &&
                    relatedProducts.length > 0 && (
                      <section className="related-products py-md-5">
                        <b2x.Container>
                          <h3 className={classnames('h1 fw-bold mb-3 mb-md-5')}>{t('product.relatedProduct.title')}</h3>
                          <b2x.Listing
                            name="Product page - Related products"
                            products={relatedProducts}
                            sendEvent={false}
                          >
                            <ProductsSlider noRelativeParent products={relatedProducts} />
                          </b2x.Listing>
                        </b2x.Container>
                      </section>
                    )
                  )
                }
              </b2x.RelatedProducts>
            </React.Fragment>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface ProductFilesProps {
  product: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductFiles = ({ product, selectedSku }: ProductFilesProps) => {
  const productFilesAttributeKeys = ['ARX_SCHEDA_TECNICA', 'ARX_SCHEDA_SICUREZZA', 'ARX_NORMATIVA_DETERGENTI'];

  const filesToShow: Array<b2x.AttributeApiDto> = [];
  productFilesAttributeKeys.forEach((key) => {
    const selectedSkuFile = selectedSku?.attributes?.filter((attribute) => attribute.typeCode.startsWith(key));
    if (!!selectedSkuFile?.length) {
      selectedSkuFile.map((file) => filesToShow.push(file));
    } else {
      const productFile = product.attributes?.filter((attribute) => attribute.typeCode.startsWith(key));
      if (!!productFile?.length) {
        productFile.map((file) => filesToShow.push(file));
      }
    }
  });

  return filesToShow.length > 0 ? (
    <b2x.Div className="product-files mt-5 mb-4">
      <b2x.Accordion id="accordion-files">
        {productFilesAttributeKeys.map((key) => {
          const attributes = filesToShow.filter((attribute) => attribute.typeCode.startsWith(key));

          return (
            attributes.length > 0 && (
              <b2x.AccordionItem
                id={`accordion-item-${key}`}
                key={key}
                title={t(`product.accordionFiles.accordionLabel.${key}` as ResourceKeys)}
              >
                <b2x.Div display="flex" flexDirection="column" gap={1}>
                  {attributes.map(({ title, value }, index) => (
                    <b2x.router.Link
                      className={classnames('text-decoration-none py-2 text-reset', {
                        'border-bottom': index + 1 !== attributes.length,
                      })}
                      key={title}
                      target="_blank"
                      to={value}
                    >
                      <b2x.Div display="flex" justifyContent="spaceBetween">
                        <b2x.P className="small text-blue" fontWeight={700} marginBottom={0}>
                          {title}
                        </b2x.P>
                        <Icon className="text-blue" name="download" size={18} />
                      </b2x.Div>
                    </b2x.router.Link>
                  ))}
                </b2x.Div>
              </b2x.AccordionItem>
            )
          );
        })}
      </b2x.Accordion>
    </b2x.Div>
  ) : (
    <></>
  );
};

interface ProductPriceProps {
  priceHelper: b2x.UsePriceResult;
}

const ProductPrice = ({ priceHelper }: ProductPriceProps) => {
  return (
    <div className="product-price-container my-4">
      <div className="mb-2">
        <b2x.PriceBlock priceHelper={priceHelper} />
      </div>
      <div className="extra-small">
        {!!priceHelper.perUnit?.price && (
          <div>
            {`(${t('misc.pricePerUnit', {
              measurementUnit: priceHelper.perUnit.measurement,
              price: b2x.formatCurrency(priceHelper.perUnit.price),
            })})`}
          </div>
        )}
        {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && <b2x.BestPrice priceHelper={priceHelper} />}
      </div>
    </div>
  );
};

interface ProductGalleryProps {
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductGallery = ({ selectedProductVariant, selectedSku }: ProductGalleryProps) => {
  const { discountPercentage } = b2x.usePrice(selectedProductVariant.priceRange, selectedSku?.price);

  const gallery = React.useMemo(() => {
    const images = [];
    const initialSlide = 0;

    if (selectedSku?.image) {
      images.push({
        src: selectedSku.image.src,
        thumbnail: selectedSku.image.src,
        type: 'image',
        uId: selectedSku.image.id,
      });
    } else {
      images.push({
        src: selectedProductVariant.image?.src,
        thumbnail: selectedProductVariant.image?.src,
        type: 'image',
        uId: selectedProductVariant.image?.id,
      });
    }
    if (selectedSku?.alternativeImages) {
      selectedSku.alternativeImages.forEach((image) =>
        images.push({ src: image.src, thumbnail: image.src, type: 'image', uId: image.id })
      );
    } else if (selectedProductVariant.alternativeImages) {
      selectedProductVariant.alternativeImages.forEach((image) =>
        images.push({ src: image.src, thumbnail: image.src, type: 'image', uId: image.id })
      );
    }

    return { images, initialSlide };
  }, [
    selectedProductVariant.alternativeImages,
    selectedProductVariant.image?.id,
    selectedProductVariant.image?.src,
    selectedSku?.alternativeImages,
    selectedSku?.image,
  ]);

  const [thumbsSwiper, setThumbsSwiper] = React.useState<b2x.SwiperClass>();
  return (
    <div className="product-gallery d-flex mb-3">
      <div className="gallery-thumbnails d-none d-lg-block">
        <b2x.SwiperContext>
          <b2x.Swiper
            direction="vertical"
            onSwiper={setThumbsSwiper}
            slides={gallery.images.map((image) => (
              <div key={image.uId}>
                <b2x.Image {...image} fluid />
              </div>
            ))}
            slidesPerView={'auto'}
            spaceBetween={16}
            watchSlidesProgress
          />
        </b2x.SwiperContext>
      </div>
      <div className="gallery-slides ms-0 ms-lg-3 position-relative">
        <b2x.Lightbox>
          <b2x.SwiperContext>
            <b2x.Swiper
              pagination
              slides={gallery.images.map((image) => (
                <div key={image.uId}>
                  {image.src && (
                    <b2x.LightboxItem src={image.src}>
                      <b2x.Image {...image} fluid />
                    </b2x.LightboxItem>
                  )}
                </div>
              ))}
              thumbs={{
                slideThumbActiveClass: 'border',
                swiper: thumbsSwiper,
              }}
            />
          </b2x.SwiperContext>
        </b2x.Lightbox>
        <div className="position-absolute start-0 top-0 pt-3 gallery-product-badge">
          {discountPercentage && (
            <b2x.Div background="primary" className="rounded-1-end" paddingX={2} paddingY={1}>
              <b2x.H5 className="ff-karla text-white" margin={0}>{`-${b2x.formatFloatPercentage(
                discountPercentage
              )}%`}</b2x.H5>
            </b2x.Div>
          )}
        </div>
        <div className="position-absolute bottom-0 gallery-product-badge w-100 px-4 pb-5">
          <ProducTiletStickers product={selectedProductVariant} selectedVariant={selectedProductVariant} size="large" />
        </div>
      </div>
    </div>
  );
};

interface ProductPromoCodePros {
  attributes?: Array<b2x.AttributeApiDto>;
}

const ProductPromoCode = ({ attributes }: ProductPromoCodePros) => {
  // Recupero il valore dall'attributo impostato.
  const productPromoCodeContent = attributes?.find(
    (attribute) => attribute.typeCode === 'ARX_PROMO_CONTENT_CODE'
  )?.value;

  const miscContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  // Se presente un valore, recupero il contenuto del banner.
  const content = productPromoCodeContent && b2x.useContent<ProductPromoCodeContentType>(productPromoCodeContent);
  const { showModal } = b2x.useModals();

  const codeRef = React.useRef<HTMLSpanElement>(null);
  const handleCopyCodeButtonClick = React.useCallback(() => {
    const couponCode = codeRef.current?.innerText ?? '';
    navigator.clipboard.writeText(couponCode).then((response) => {
      showModal({
        autoClose: 2,
        centered: true,
        children: miscContent?.body.productPage?.couponCodeBanner?.contentModal,
        className: 'modal-copy-coupon',
        closable: false,
        title: miscContent?.body.productPage?.couponCodeBanner?.titleModal || '',
      });
    });
  }, [
    miscContent?.body.productPage?.couponCodeBanner?.contentModal,
    miscContent?.body.productPage?.couponCodeBanner?.titleModal,
    showModal,
  ]);

  return (
    <>
      {typeof content === 'object' && content.body.backgroundColor && (
        <div
          className="product-promo-code mb-4 p-2"
          style={{
            background: content.body.backgroundColor,
          }}
        >
          <b2x.Row className="align-items-center" gap={3}>
            <b2x.Col size={'auto'}>
              {content.body.icon ? (
                <Icon className="text-red me-3 me-xl-0" name={content.body.icon as IconName} size={25} />
              ) : (
                <b2x.LottieElement className="ringbell" src={animazioneCampanella as unknown as string} />
              )}
            </b2x.Col>
            <b2x.Col size={''}>
              <div className="small ">{b2x.formatHtml(content.body.description)}</div>
              <div className="extra-small mb-1 mb-xl-0">
                <span className="text-uppercase extra-small"> {t('misc.code')}: </span>
                <span className="fw-bold extra-small " ref={codeRef}>
                  {b2x.formatHtml(content.body.couponCode)}
                </span>
              </div>
            </b2x.Col>

            <b2x.Col className="button-col" size={{ sm: 'auto', xl: 'auto', xs: 12 }}>
              <div className="pb-3 pb-sm-0">
                <b2x.Button
                  label={content.body.cta?.label}
                  onClick={handleCopyCodeButtonClick}
                  variant={content.body.cta?.variant}
                />
              </div>
            </b2x.Col>
          </b2x.Row>
        </div>
      )}
    </>
  );
};

interface KitContentSectionPros {
  product: b2x.ProductApiDto;
}

const KitContentSection = ({ product }: KitContentSectionPros) => {
  if (!product.assembled || !product.assembledComponents?.length) {
    return null;
  }

  const productsSkus: Array<b2x.SkuApiDto> = [];
  product.assembledComponents.forEach((ac) => {
    const sku = ac.componentSkus?.at(0)?.sku;
    if (sku) {
      productsSkus.push(sku);
    }
  });

  return (
    <section className="py-sm-5 py-4 bg-white kit-content-section">
      <Container>
        <b2x.H2
          className="text-center h1"
          marginBottom={{
            lg: 5,
            xs: 4,
          }}
        >
          {t('product.kitSection.title')}
        </b2x.H2>
        <b2x.EqualHeight>
          <b2x.SwiperContext>
            <b2x.Swiper
              breakpoints={{
                sm: {
                  slidesPerView: 3,
                },
                xs: {
                  slidesPerView: 1.2,
                },
              }}
              className="swiper-button-white"
              navigation
              slides={productsSkus.map((sku) => (
                <PreviewProductSkuCard key={sku.id} sku={sku} />
              ))}
              spaceBetween={20}
            />
          </b2x.SwiperContext>
        </b2x.EqualHeight>
      </Container>
    </section>
  );
};

interface PreviewProductSkuCardPros {
  sku: b2x.SkuApiDto;
}

const PreviewProductSkuCard = ({ sku }: PreviewProductSkuCardPros) => {
  const product = sku.product;
  if (!product) {
    return null;
  }

  const { assembled, brand, description, image, name } = product;
  return (
    <b2x.Div display="flex" flexDirection="column">
      {image && <b2x.Image {...product.image} fluid />}
      {brand?.name && (
        <b2x.EqualHeightElement name="brand-name">
          <b2x.P marginBottom={2}>{brand.name}</b2x.P>
        </b2x.EqualHeightElement>
      )}
      {name && (
        <b2x.EqualHeightElement name="product-name">
          <b2x.H3 marginBottom={2}>{product.name}</b2x.H3>
        </b2x.EqualHeightElement>
      )}
      {sku.measurement && !assembled && (
        <b2x.P
          marginBottom={{
            lg: 3,
            xs: 2,
          }}
        >
          {b2x.formatHtml(
            t('productTile.measurement', {
              measurementUnit: sku.measurement.measurementUnit?.toLowerCase(),
              value: sku.measurement.value,
            })
          )}
        </b2x.P>
      )}
      {description && (
        <b2x.Div marginBottom={3}>
          <b2x.ShowMore content={description} truncateTo={250} />
        </b2x.Div>
      )}
      <ProductFiles product={product} />
    </b2x.Div>
  );
};

interface VideoSectionPros {
  product: b2x.ProductApiDto;
}

const VideoSection = ({ product }: VideoSectionPros) => {
  const video = product.attributes?.find((content) => content.typeCode === 'ARX_VIDEO_SCHEDA_PRODOTTO');

  if (!video || !video.value) {
    return null;
  }

  return (
    <section className="py-sm-5 py-4 bg-secondary">
      <b2x.Div className="my-lg-2">
        <Container>
          <h2 className="fw-bold text-white text-center mb-sm-5 mb-4">{t('product.videoSection.title')}</h2>
          <div className="video-container">
            <b2x.Video
              variants={{
                xs: {
                  height: 500,
                  source: {
                    youtube: {
                      id: video.value,
                    },
                  },
                  width: 900,
                },
              }}
            />
          </div>
        </Container>
      </b2x.Div>
    </section>
  );
};
