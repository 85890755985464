import './PageHeaderBanner.scss';

import { b2x } from '@b2x/react/src';

import { Container } from './Container';
import { HeaderBannerContentType } from './contentTypes';

export interface PageHeaderBannerProps extends HeaderBannerContentType {}

export const PageHeaderBanner = ({
  asset,
  backgroundColor,
  colorSubTitle,
  colorTitle,
  subTitle,
  title,
}: PageHeaderBannerProps) => {
  return (
    <section className="page-header-banner-section">
      <Container className="p-0">
        <b2x.Div
          alignItems="center"
          background={backgroundColor}
          className="page-header-banner"
          display="flex"
          flexDirection={{
            md: 'row',
            xs: 'column',
          }}
          gap={3}
          paddingX={{
            md: 5,
            xs: 3,
          }}
          paddingY={{
            md: 4,
            xs: 3,
          }}
        >
          <div className="order-md-1 order-2">
            <b2x.Breadcrumb
              classNameObject={{
                a: `text-${colorTitle}`,
                ol: 'mb-2 justify-content-center justify-content-md-start',
              }}
            />
            <b2x.H1
              textAlign={{
                md: 'start',
                xs: 'center',
              }}
              uiClassName={{
                bs5: `text-${colorTitle}`,
              }}
            >
              {title}
            </b2x.H1>
            <b2x.P
              display={{
                md: 'block',
                xs: 'none',
              }}
              margin={0}
              uiClassName={{
                bs5: `text-${colorSubTitle}`,
              }}
            >
              {subTitle}
            </b2x.P>
          </div>
          {asset?.type && (
            <div className="order-md-2 order-1">
              <b2x.AssetV1 {...asset} fluid />
            </div>
          )}
        </b2x.Div>
        {subTitle && (
          <b2x.Div
            display={{
              md: 'none',
              xs: 'block',
            }}
            margin={0}
            marginTop={3}
            paddingX={{
              md: 5,
              xs: 3,
            }}
          >
            <b2x.ShowMore activeOnBreakpoint={{ md: true, sm: true, xs: true }} content={subTitle} truncateTo={200} />
          </b2x.Div>
        )}
      </Container>
    </section>
  );
};
