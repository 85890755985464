import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { Button, ButtonVariant } from './Button';
import { CustomVariantRadio } from './CustomVariantRadio';
import { t } from './i18n/i18n';
import { Icon, IconName, IconSize } from './Icon';
import { ProducTiletStickers } from './ProductTileStickers';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = (props: ProductTileProps) => {
  return <b2x.ProductTile className={'card overflow-hidden mb-3 mb-lg-0'} {...props} enableExtraInfo />;
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = (props: ProductTileBasicInfoProps) => (
  <b2x.ProductTileBasicInfo {...props} customComponent={CustomProductTileBasicInfo} />
);

const CustomProductTileBasicInfo = ({ product, productsPerRow, sku }: b2x.ProductTileBasicInfoProps) => {
  const image = sku?.image ?? product.image;
  const { discountPercentage } = b2x.usePrice(product.priceRange, sku?.price);

  const forcedSku = sku ?? product.skus?.at(0);

  return (
    <div className="product-tile-basic-info">
      <div className="media-container position-relative bg-gray-200 mb-3">
        <b2x.router.Link to={product.url}>
          <b2x.EqualHeightElement name={`tile-media-${productsPerRow}`}>
            <React.Fragment>{image && <b2x.Image {...image} fluid />}</React.Fragment>
          </b2x.EqualHeightElement>
        </b2x.router.Link>
        <div className="position-absolute start-0 top-0 pt-3">
          {discountPercentage && (
            <div className="bg-primary text-white fw-bold px-2 rounded-1-end">{`-${b2x.formatFloatPercentage(
              discountPercentage
            )}%`}</div>
          )}
        </div>
        <div className="position-absolute end-0 top-0">
          {b2x.appConfig.enableWishlist && (
            <b2x.WishlistButtonHelper product={product} sku={sku}>
              {({ handleWishlistButtonClick, inWishlist }) => (
                <Button
                  className="p-3"
                  iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 24 }}
                  onClick={handleWishlistButtonClick}
                  size="large"
                  type="button"
                  variant="blank"
                />
              )}
            </b2x.WishlistButtonHelper>
          )}
        </div>
        <div className="product-tile-stickers-container position-absolute start-0 bottom-0 w-100 p-3">
          <ProducTiletStickers product={product} selectedVariant={product} />
        </div>
      </div>
      <div className="px-3 mb-3">
        <b2x.EqualHeightElement name={`tile-info-${productsPerRow}`}>
          <p className="m-0">{product.brand?.name}</p>
          <h6>
            <b2x.router.Link className="text-black text-decoration-none" to={product.url}>
              {product.name}
            </b2x.router.Link>
          </h6>
          {forcedSku?.measurement && (
            <p className="m-0 extra-small">
              {b2x.formatHtml(
                t('productTile.measurement', {
                  measurementUnit: forcedSku.measurement.measurementUnit?.toLowerCase(),
                  value: forcedSku.measurement.value,
                })
              )}
            </p>
          )}
        </b2x.EqualHeightElement>
      </div>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo customComponent={CustomProductTileExtraInfo} {...props} />
);

const CustomProductTileExtraInfo = ({
  fieldsHelper,
  priceHelper,
  product,
  productsPerRow,
  selectedSku,
}: ProductTileExtraInfoProps) => {
  const variantSkuToShow = 4;
  const inStock = product.skus?.find((sku) => sku.state === 'AVAILABLE');

  return (
    <div className="product-tile-extra-info px-3 pb-3">
      <b2x.EqualHeightElement name={`tile-options-${productsPerRow}`}>
        {/* Varianti prodotto - Formato (ml) */}
        {fieldsHelper.productVariants.formFields.length > 1 && (
          <b2x.FormGroup {...fieldsHelper.productVariants.formGroup} className="mb-2" label={undefined} noMarginBottom>
            <b2x.SwiperContext>
              <b2x.Swiper className={'auto-width'} slidesPerView={'auto'} spaceBetween={16}>
                {fieldsHelper.productVariants.formFields.map((formField) => {
                  const variantFirstSku = formField.productVariant.skus?.at(0);

                  return (
                    <b2x.SwiperSlide key={formField.productVariant.id}>
                      <b2x.Radio {...formField.radio} inline noMargin>
                        {variantFirstSku && <CustomVariantRadio sku={variantFirstSku} />}
                      </b2x.Radio>
                    </b2x.SwiperSlide>
                  );
                })}
              </b2x.Swiper>
            </b2x.SwiperContext>
          </b2x.FormGroup>
        )}
        {/* Varianti Sku - Profumo (immagine di sku) e Colore (per attributo) */}
        {fieldsHelper.skus.formFields.length > 1 && (
          <b2x.FormGroup
            {...fieldsHelper.skus.formGroup}
            className="d-flex gap-2 mb-2"
            label={undefined}
            noMarginBottom
          >
            {fieldsHelper.skus.formFields.map(({ radio, sku }, index) => (
              <React.Fragment key={sku.id}>
                {index < variantSkuToShow && (
                  <b2x.Radio noMargin {...radio} inline>
                    <b2x.router.Link to={product.url}>
                      <CustomSkuRadio product={product} sku={sku} />
                    </b2x.router.Link>
                  </b2x.Radio>
                )}
              </React.Fragment>
            ))}
            {fieldsHelper.skus.formFields.length > variantSkuToShow && (
              <div className="d-flex align-items-center ps-2">
                <b2x.router.Link className="text-gray-600 extra-small" to={product.url}>
                  {t('productTile.moreSkuAvailable', {
                    value: fieldsHelper.skus.formFields.length - variantSkuToShow,
                  })}
                </b2x.router.Link>
              </div>
            )}
          </b2x.FormGroup>
        )}
      </b2x.EqualHeightElement>

      {priceHelper && (
        <div className="mb-3">
          <b2x.PriceBlock
            classNames={{ specialPrice: 'fw-bold text-primary' }}
            gap={2}
            hiddenDiscountPercentage
            priceHelper={priceHelper}
          />
          <b2x.BestPrice priceHelper={priceHelper} />
        </div>
      )}
      <b2x.Row alignItems={'center'} gap={0}>
        {inStock ? (
          <React.Fragment>
            {fieldsHelper.skus.formFields.length > 1 ? (
              <React.Fragment>
                <b2x.Col className="d-none d-lg-block" size={''}>
                  <p className="small m-0 pe-3 lh-sm">{t('productTile.seeAllSkuOptions')}</p>
                </b2x.Col>
                <b2x.Col size={{ lg: 'auto', xs: 12 }}>
                  <b2x.router.Link
                    className="btn btn-primary d-flex justify-content-center justify-content-lg-start align-items-center gap-2"
                    to={product.url}
                  >
                    {t('productTile.choseSkuVariant')}
                    <Icon name="cart" size={20} />
                  </b2x.router.Link>
                </b2x.Col>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <b2x.Col className="d-none d-lg-block" size={''}>
                  <b2x.FormGroup
                    className="tile-quantity-container"
                    {...fieldsHelper.quantity.formGroup}
                    label={undefined}
                    noMarginBottom
                  >
                    <div className="d-flex">
                      <div className="btn-container" style={{ flex: '0 0 auto' }}>
                        <b2x.Button
                          className="px-0"
                          variant="gray-200"
                          {...fieldsHelper.buttons.quantity.decrease}
                          iconStart={{ name: 'minus', size: 14 }}
                          label={undefined}
                        />
                      </div>
                      <div className="px-2" style={{ flex: '0 0 auto', width: 40 }}>
                        <b2x.NumberInput
                          className="border-0 text-center fw-bold bg-transparent px-0 py-2"
                          {...fieldsHelper.quantity.numberInput}
                          placeholder={undefined}
                          readOnly
                        />
                      </div>
                      <div className="btn-container" style={{ flex: '0 0 auto' }}>
                        <b2x.Button
                          className="px-0"
                          variant="gray-200"
                          {...fieldsHelper.buttons.quantity.increase}
                          iconStart={{ name: 'plus', size: 14 }}
                          label={undefined}
                        />
                      </div>
                    </div>
                  </b2x.FormGroup>
                </b2x.Col>
                <b2x.Col size={{ lg: 'auto', xs: 12 }}>
                  <div className="d-grid">
                    <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                      fieldsHelper={fieldsHelper}
                      selectedSku={selectedSku}
                      submitButton={{
                        iconEnd: { name: 'cart', size: 20 },
                        justifyContent: 'center',
                        label: t('productTile.addToCart'),
                      }}
                    />
                  </div>
                </b2x.Col>
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <b2x.Col className="d-none d-lg-block" size={''}>
              <p className="small m-0 pe-3 lh-sm">{t('productTile.notAvailableMessage')}</p>
            </b2x.Col>
            <b2x.Col size={{ lg: 'auto', xs: 12 }}>
              <b2x.router.Link
                className="btn btn-outline-primary d-flex justify-content-center justify-content-lg-start align-items-center gap-2"
                to={product.url}
              >
                {t('productTile.notAvailable')}
              </b2x.router.Link>
            </b2x.Col>
          </React.Fragment>
        )}
      </b2x.Row>
    </div>
  );
};

interface CustomSkuRadioProps {
  product: b2x.ProductApiDto;
  sku: b2x.SkuApiDto;
}

const CustomSkuRadio = ({ product, sku }: CustomSkuRadioProps) => {
  const colorHexCode = sku.attributes?.find((attribute) => attribute.typeCode === 'ARX_COLORE_HEX')?.value;
  const image = sku.image ?? product.image;
  return (
    <div className="custom-sku-radio">
      {colorHexCode ? (
        <div className="sku-color">
          <div style={{ backgroundColor: colorHexCode }}></div>
        </div>
      ) : (
        image && (
          <div className="sku-image">
            <div>
              <b2x.Image {...image} fluid />
            </div>
          </div>
        )
      )}
    </div>
  );
};
