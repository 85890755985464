import { b2x } from '@b2x/react/src';

import {
  BannerSectionContentType,
  LandingTemplateAContentType,
  ProductsBannerSectionContentType,
} from '../contentTypes';
import { PageHeaderBanner } from '../PageHeaderBanner';
import { Section } from '../Section';
import { ProductsSlider } from '../slider/ProductsSlider';
import { Page } from './Page';

export const LandingTemplateAPage = () => {
  const page = b2x.usePage<LandingTemplateAContentType>();

  const body = page?.content?.body;
  return (
    <Page greyBackground noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {body?.header && <PageHeaderBanner {...body.header} />}
      {body?.sections?.map((section) => {
        if (section.component === 'banner') {
          return <BannerSection key={section.contentSectionId} {...section.banner} />;
        }

        if (section.component === 'products') {
          return <ProductsBannerSection key={section.contentSectionId} {...section.products} />;
        }

        return null;
      })}
    </Page>
  );
};

interface BannerSectionProps extends BannerSectionContentType {}

const BannerSection = ({ asset, content, cta, title }: BannerSectionProps) => {
  const { getPagePath } = b2x.useAppStaticContext();
  return (
    <Section className="mt-5">
      <b2x.Row>
        <b2x.Col
          size={{
            lg: 7,
            xs: 12,
          }}
        >
          {title && (
            <b2x.H2
              className="ff-karla"
              display={{
                lg: 'none',
                xs: 'block',
              }}
              fontWeight={600}
              marginBottom={3}
              textAlign="center"
            >
              {title}
            </b2x.H2>
          )}
          {asset?.type && (
            <b2x.Div
              textAlign={{
                lg: 'start',
                xs: 'center',
              }}
            >
              <b2x.router.Link to={cta?.to?.href ? cta.to.href : cta?.to?.code && getPagePath(cta.to.code)}>
                <b2x.AssetV1 {...asset} className="rounded-2" fluid />
              </b2x.router.Link>
            </b2x.Div>
          )}
        </b2x.Col>

        <b2x.Col
          className="my-auto"
          size={{
            lg: 5,
            xs: 12,
          }}
        >
          <b2x.Div>
            {title && (
              <b2x.H2
                className="ff-karla"
                display={{
                  lg: 'block',
                  xs: 'none',
                }}
                fontWeight={600}
              >
                {title}
              </b2x.H2>
            )}
            {content && <b2x.P marginTop={3}>{content}</b2x.P>}
            {cta && (
              <b2x.Div
                display="flex"
                flexDirection={{
                  lg: 'row',
                  xs: 'column',
                }}
                marginTop={4}
              >
                <b2x.CtaFromContent {...cta} />
              </b2x.Div>
            )}
          </b2x.Div>
        </b2x.Col>
      </b2x.Row>
    </Section>
  );
};

interface ProductsBannerSectionProps extends ProductsBannerSectionContentType {}

const ProductsBannerSection = ({ asset, cta, productsList, title }: ProductsBannerSectionProps) => {
  const { getPagePath } = b2x.useAppStaticContext();
  return (
    <Section className="mt-5">
      <b2x.H2 className="ff-karla" fontWeight={600} textAlign="center">
        {title}
      </b2x.H2>
      <b2x.Row className="mt-1" gap={3}>
        <b2x.Col
          size={{
            lg: 6,
            xs: 12,
          }}
        >
          {asset?.type && (
            <b2x.Div
              textAlign={{
                lg: 'start',
                xs: 'center',
              }}
            >
              <b2x.router.Link to={cta?.to?.href ? cta.to.href : cta?.to?.code && getPagePath(cta.to.code)}>
                <b2x.AssetV1 {...asset} className="rounded-2" fluid />
              </b2x.router.Link>
            </b2x.Div>
          )}
        </b2x.Col>
        <b2x.Col
          size={{
            lg: 6,
            xs: 12,
          }}
        >
          <b2x.ProductsByIds ids={productsList?.map(({ productId }) => productId) ?? []}>
            {(products) =>
              products !== undefined && (
                <b2x.EqualHeight>
                  <b2x.Listing name="Promotion page - promo products" products={products}>
                    <ProductsSlider
                      breakpoints={{
                        lg: {
                          slidesPerView: 2,
                        },
                        sm: {
                          slidesPerView: 2.5,
                        },
                        xs: {
                          slidesPerView: 1.3,
                        },
                      }}
                      products={products}
                    />
                  </b2x.Listing>
                </b2x.EqualHeight>
              )
            }
          </b2x.ProductsByIds>
        </b2x.Col>
      </b2x.Row>
      {cta?.label && (
        <b2x.Div marginTop={4} textAlign="center">
          <b2x.CtaFromContent {...cta} />
        </b2x.Div>
      )}
    </Section>
  );
};
