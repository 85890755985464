import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType, HeaderMenuItemContentType } from './contentTypes';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import logo from './images/logo.svg';

export interface DesktopHeaderProps {
  activeCategory?: b2x.MenuApiDto;
  content?: b2x.ContentApiDto<HeaderContentType>;
  firstRowRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  setSearchBoxVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({
  activeCategory,
  content,
  firstRowRef,
  recalculateHeaderHeight,
  searchBoxVisible,
  setActiveCategory,
  setSearchBoxVisible,
  visibleFrom,
}: DesktopHeaderProps) => {
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();
  //const { session } = b2x.useAppContext();
  //const { headerCheckout } = b2x.useAppContext();

  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  const menu = b2x.useMenu('MENU_HEADER_DESKTOP', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_HEADER_DESKTOP: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const toggleSearchBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible((prevState) => !prevState);
  }, [setActiveCategory, setSearchBoxVisible]);

  return (
    <b2x.Sticky offset={-1}>
      {({ isSticky }) => (
        <div
          className={classnames('desktop-header py-2 sticky-top bg-white shadow-sm', `d-none d-${visibleFrom}-block`)}
          ref={firstRowRef}
        >
          <Container>
            <b2x.Row alignItems={'center'} gap={0}>
              <b2x.Col size={'auto'}>
                <b2x.Div alignItems={'start'} className="h-100" display={'flex'}>
                  <div className="logo-container position-relative me-2" style={{ zIndex: 100 }}>
                    {isSticky ? (
                      <div className="logo">
                        <Logo recalculateHeaderHeight={recalculateHeaderHeight} type="small" />
                      </div>
                    ) : (
                      <div className="logo-regular">
                        <Logo recalculateHeaderHeight={recalculateHeaderHeight} type="regular" />
                      </div>
                    )}
                  </div>
                  <div className="align-self-center">
                    <Menu
                      activeCategory={activeCategory}
                      menu={menu}
                      searchBoxVisible={searchBoxVisible}
                      setActiveCategory={setActiveCategory}
                      subMenuDropdownRef={subMenuDropdownRef}
                    />
                  </div>
                </b2x.Div>
              </b2x.Col>
              <b2x.Col alignItems={'center'} display={'flex'} justifyContent={'end'}>
                <Toggles toggleSearchBoxVisible={toggleSearchBoxVisible} />
              </b2x.Col>
            </b2x.Row>
          </Container>
          {activeCategory && (
            <SubMenuDropdown
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              subMenuDropdownRef={subMenuDropdownRef}
            />
          )}
          {searchBoxVisible && <SearchBox toggleSearchBoxVisible={toggleSearchBoxVisible} />}
        </div>
      )}
    </b2x.Sticky>
  );
};

interface TogglesProps {
  toggleSearchBoxVisible(): void;
}

const Toggles = ({ toggleSearchBoxVisible }: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <div className="toggles">
      <div className="hstack gap-3">
        <Button
          className="p-2"
          iconEnd={{ name: 'search', size: 25 }}
          onClick={toggleSearchBoxVisible}
          variant="blank"
        />
        {session?.customer ? (
          <b2x.router.Link className="lh-1 text-primary p-2" to={getPagePath('SITE_ACCOUNT')}>
            <Icon name="account" size={25} />
          </b2x.router.Link>
        ) : (
          <Button
            className="text-primart"
            iconEnd={{ name: 'account', size: 25 }}
            onClick={showAccountOffcanvas}
            variant="blank"
          />
        )}
        {b2x.appConfig.enableWishlist &&
          (session?.customer ? (
            <b2x.router.Link className="btn-wishlist lh-1 p-2" to="/account/area/wishlist">
              <Button className="position-relative text-secondary" variant="blank">
                <Icon name={'wishlist'} size={25} />
                {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                  (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                  <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge text-primary">
                    {(session.wishlist.products ? session.wishlist.products.length : 0) +
                      (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                  </span>
                )}
              </Button>
            </b2x.router.Link>
          ) : (
            <Button
              className="btn-wishlist text-secondary p-2"
              iconEnd={{ name: 'wishlist', size: 25 }}
              onClick={showAccountOffcanvas}
              variant="blank"
            />
          ))}
        <Button className="btn-cart position-relative text-secondary" onClick={showCartOffcanvas} variant="blank">
          <Icon name={'cart'} size={25} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge text-primary">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

interface LogoProps {
  recalculateHeaderHeight(): void;
  type: 'regular' | 'small';
}

const Logo = ({ recalculateHeaderHeight, type }: LogoProps) => {
  return (
    <b2x.router.Link to="/">
      <b2x.Image onLoad={recalculateHeaderHeight} src={type === 'regular' ? logo : logo} />
    </b2x.router.Link>
  );
};

interface SearchBoxProps {
  toggleSearchBoxVisible(): void;
}

const SearchBox = ({ toggleSearchBoxVisible }: SearchBoxProps) => {
  return (
    <Box className="search-box" onCloseButtonClick={toggleSearchBoxVisible}>
      <b2x.Row className="justify-content-center">
        <b2x.Col size={6}>
          <b2x.SimpleSearchForm
            className="flex-grow-1 border-bottom"
            onSuccess={toggleSearchBoxVisible}
            submitIconName="search"
          />
        </b2x.Col>
      </b2x.Row>
    </Box>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  className?: string;
  onCloseButtonClick(): void;
}

const Box = ({ children, className, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className={classnames(className, 'box pt-3 pb-4')} ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button iconEnd={{ name: 'close', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
        {children}
      </Container>
    </div>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item px-2 px-xl-3 d-flex align-items-center', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  className?: string;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  className,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <b2x.router.Link
        className={classnames(
          'menu-item-first-level fw-medium text-decoration-none text-nowrap px-0 border-5 border-bottom',
          firstLevelCategory.cssClass
        )}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}
      >
        {firstLevelCategory.label}
      </b2x.router.Link>
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  searchBoxVisible: boolean;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({ activeCategory, menu, searchBoxVisible, setActiveCategory, subMenuDropdownRef }: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
    }
  }, [idActiveCategory, setActiveCategory]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 100);

  return (
    <div className="menu d-flex">
      {menu?.children.map(
        (firstLevelCategory) =>
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              <b2x.router.Link
                className={classnames(
                  'menu-item-first-level text-decoration-none border-5 border-bottom text-nowrap d-inline-flex',
                  firstLevelCategory.cssClass,
                  {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  }
                )}
                to={firstLevelCategory.link}
              >
                {firstLevelCategory.label}
              </b2x.router.Link>
            </MenuItemContainer>
          ))
      )}
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const { getPagePath } = b2x.useAppStaticContext();
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <div className={classnames({ mounted: isMounted }, 'sub-menu-dropdown w-100')} ref={subMenuDropdownRef}>
      <Container>
        <b2x.Row className="justify-content-between bg-white rounded mt-2 p-4" cols={'auto'}>
          {/* {activeCategory.id} */}
          {activeCategory.children.map((secondLevelCategory, index) => (
            <b2x.Col
              className={classnames(
                ' flex-column px-3 px-xxl-4',
                activeCategory.children.length - 1 === index && activeCategory.children.length === 6
                  ? 'd-none d-xl-flex'
                  : 'd-flex'
              )}
              key={secondLevelCategory.id}
            >
              {secondLevelCategory.children.map((thirdLevelCategory) => (
                <div key={thirdLevelCategory.id}>
                  {thirdLevelCategory.content &&
                    b2x.typedContent<HeaderMenuItemContentType>(thirdLevelCategory.content, (content) => (
                      <b2x.ConditionalWrapper
                        condition={content.body.cta !== undefined}
                        wrapper={
                          <b2x.router.Link
                            to={
                              content.body.cta?.to?.href
                                ? content.body.cta.to.href
                                : content.body.cta?.to?.code && getPagePath(content.body.cta.to.code)
                            }
                          />
                        }
                      >
                        <b2x.AssetV1 {...content.body.asset} className="rounded-2" fluid />
                      </b2x.ConditionalWrapper>
                    ))}
                  {thirdLevelCategory.children.length === 0 && thirdLevelCategory.link && (
                    <h6 className="small text-uppercase fw-bold mt-3">
                      <b2x.router.Link className="text-reset" onClick={close} to={thirdLevelCategory.link}>
                        {thirdLevelCategory.label}
                      </b2x.router.Link>
                    </h6>
                  )}

                  {thirdLevelCategory.children.map((fourLevelCategory) => (
                    <div key={fourLevelCategory.id}>
                      {fourLevelCategory.children.length > 0 ? (
                        <ul className="list-unstyled" style={{ maxWidth: '250px' }}>
                          <div className="mb-3">
                            <h5>
                              <b2x.router.Link
                                className="text-reset text-decoration-none"
                                onClick={close}
                                to={fourLevelCategory.link}
                              >
                                {fourLevelCategory.label}
                              </b2x.router.Link>
                            </h5>
                          </div>
                          {fourLevelCategory.children.map(
                            (fifthLevelCategory) =>
                              fifthLevelCategory.link && (
                                <li className="pb-2" key={fifthLevelCategory.id}>
                                  <b2x.router.Link
                                    className="text-reset text-decoration-none"
                                    onClick={close}
                                    to={fifthLevelCategory.link}
                                  >
                                    <span className="h5 ff-karla fw-medium">{fifthLevelCategory.label}</span>
                                  </b2x.router.Link>
                                </li>
                              )
                          )}
                        </ul>
                      ) : (
                        <h5 className="">
                          <b2x.router.Link
                            className="text-reset text-decoration-none"
                            onClick={close}
                            to={fourLevelCategory.link}
                          >
                            {fourLevelCategory.label}
                          </b2x.router.Link>
                        </h5>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </b2x.Col>
          ))}
          {activeCategory.token && (
            <b2x.Col className="text-center" size={12}>
              <b2x.router.Link className="text-reset fw-bold" onClick={close} to={activeCategory.token}>
                {t('misc.viewAll')} {activeCategory.label}
              </b2x.router.Link>
            </b2x.Col>
          )}
        </b2x.Row>
      </Container>
    </div>
  );
};
